import React, { useState } from "react";
import illustration from "../../assets/img/Illustration.png";
import arrow from "../../assets/icons/auth_arrow.svg";
import { useNavigate } from "react-router-dom";
import google from "../../assets/icons/Google svg.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import eye from "../../assets/icons/eye.png";
import loadingPic from "../../assets/icons/loading-1.gif";

const SignUp = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const validate =
    !firstName ||
    !lastName ||
    !email ||
    !password ||
    !phone_no ||
    !confirmPassword ||
    !isChecked;

  const handleJoin = () => {
    setIsLoading(true);
    const newUserData = {
      firstName: firstName,
      lastName: lastName,
      phone_no: phone_no,
      email: email,
      password: password,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/signup`, newUserData)

      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          localStorage.setItem("email", email);
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("lastName", lastName);
          localStorage.setItem(
            "verificationToken",
            response.data.verificationToken
          );
          localStorage.setItem("password", password);
          localStorage.setItem("phone_no", phone_no);
          setIsLoading(true);
        }
      })
      .catch(({ error, response }) => {
        // console.log(response);
        if (!response) {
          toast("Please check internet connection", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.fields.email, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="h-[80vh] w-full flex flex-col justify-center items-center">
          <img src={loadingPic} />
          <div className="text-center flex justify-center">
            <p className="font-semibold w-[85%] text-[14px] xs:text-[16px] sm:text-[20px] bg-[#e2940e98] px-2 p-1 rounded-[18px]">
              Kindly close the page and check your mail...
            </p>
          </div>
        </div>
      ) : (
        <div className="flex">
          <div className="bg-[#f1730066] w-[500px] px-7 lg:px-0 md:w-[700px] lg:w-[900px] hidden md:flex justify-center items-center">
            <div className="flex flex-col items-center justify-center gap-[50px]">
              <img src={illustration} />
              <p className="text-[26px] text-white lg:text-[31px] font-semibold lg:w-[95%] text-center">
                Online Community For Post-production Professionals
              </p>
            </div>
          </div>
          <div className="bg-white w-full">
            <div className="pt-[15px] px-[15px] xs:px-[30px]">
              <img
                onClick={() => navigate("/")}
                className="cursor-pointer"
                src={arrow}
              />
            </div>

            <div className="w-full flex justify-center my-10">
              <div className="w-[85%] xs:w-[80%] sm:w-[65%] lg:w-[60%] xl:w-[50%] flex flex-col gap-[32px]">
                <div className="flex flex-col gap-[48px]">
                  <div>
                    <p className="text-[25px] text-center lg:text-[30px]">
                      Join & Connect the Fastest Growing Online Community
                    </p>
                  </div>

                  <div>
                    <button className="flex font-semibold gap-[8px] h-[48px] w-full justify-center items-center shadow shadow-[#f17400b4] rounded-[12px]">
                      <img src={google} />
                      Signup with Google
                    </button>
                  </div>

                  <div className="flex flex-col gap-[20px] xs:gap-[30px] font-semibold">
                    <div className="flex flex-col gap-[8px]">
                      <p>First Name</p>
                      <input
                        className="w-full h-[47px] p-[5px] font-semibold border-b border-black text-[#ABABAB]"
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>

                    <div className="flex flex-col gap-[8px]">
                      <p>Last Name</p>
                      <input
                        className="w-full h-[47px] p-[5px] font-semibold border-b border-black text-[#ABABAB]"
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>

                    <div className="flex flex-col gap-[8px]">
                      <p>Email</p>
                      <input
                        type="email"
                        className="w-full h-[47px] p-[5px] border-b border-black font-semibold text-[#ABABAB]"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="flex flex-col gap-[8px]">
                      <p>Phone Number</p>
                      <input
                        className="w-full h-[47px] p-[5px] border-b border-black font-semibold text-[#ABABAB]"
                        required
                        value={phone_no}
                        onChange={(e) => setPhone_no(e.target.value)}
                      />
                    </div>

                    <div className="flex flex-col gap-[8px]">
                      <p>Password</p>
                      <div className="flex items-center pr-1 bg-[#fff] border-b border-black">
                        <input
                          className="w-full h-[47px] p-[5px] border-b border-black font-semibold text-[#ABABAB]"
                          required
                          value={password}
                          placeholder="minimum of 8 items"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div
                          onClick={togglePasswordVisibility}
                          className="pl-1"
                        >
                          <img src={eye} className="w-4" alt="/" />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                      <p>Confirm Password</p>
                      <div className="flex items-center pr-1 bg-[#fff] border-b border-black">
                        <input
                          className="w-full h-[47px] p-[5px] border-b border-black font-semibold text-[#ABABAB]"
                          required
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div
                          onClick={togglePasswordVisibility2}
                          className="pl-1"
                        >
                          <img src={eye} className="w-4" alt="/" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="text-[#757575] text-[10px] flex gap-2 items-center">
                      <input
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        type="checkbox"
                      />
                      <p>I accept the terms and condition</p>
                    </div>

                    <button
                      onClick={handleJoin}
                      disabled={validate}
                      className={`flex shadow w-[110px] shadow-[#F17300] ${
                        !validate ? "bg-[#F17300] text-white" : ""
                      } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
                    >
                      {isLoading ? "Loading..." : "Join in"}
                    </button>
                  </div>

                  <div className="flex justify-center">
                    <p>
                      Own an Account?{" "}
                      <span
                        onClick={() => navigate("/Login")}
                        className="underline font-semibold cursor-pointer"
                      >
                        log in
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default SignUp;
