import React, { ReactNode, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Notification from "../Notification";
import ProfileDropdowm from "../Profile_Dropdown";
import LogoutModal from "../logoutModal/logoutModal";


interface DashboardLayoutProps {
  children: ReactNode;
}

const Layout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const [showNotif, setShowNotif] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleToggle = () => {
    setShowDropdown(false)
    setShowNotif(!showNotif)
  }

  const dropClick = () => {
    setShowNotif(false)
    setShowDropdown(!showDropdown)
  }

  const handleLockout = () => {
    setShowDropdown(false)
    setShowLogoutModal(!showLogoutModal)
  }

  return (
    <div>
      <Header DropdownClick={dropClick} NotifClick={handleToggle} />
      <main>{children}</main>
      <Footer />

      {showNotif && <Notification />}
      {showDropdown && <ProfileDropdowm onDropdownClick={handleLockout} />}

      {showLogoutModal && (
        <>
          <div className=" fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
            <LogoutModal onClose={() => setShowLogoutModal(false)} />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}
    </div>
  );
};

export default Layout;
