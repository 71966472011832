import React, { useRef } from "react";
import "./ScrollableDivWithNavigation.css";
import rightdir from "../../../assets/icons/left_dir.svg";
import leftdir from "../../../assets/icons/right_dir.svg";

type VideoNavProps = {
  videoNav?: any;
};

const VideoNav: React.FC<VideoNavProps> = ({ videoNav }) => {
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollableDivRef.current) {
      if (scrollableDivRef.current.scrollLeft > 200) {
        scrollableDivRef.current.scrollLeft -= 200;
      } else {
        scrollableDivRef.current.scrollLeft = 0;
      }
    }
  };

  const scrollRight = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollLeft += 200;
    }
  };

  return (
    <div className="flex items-center">
      <button onClick={scrollLeft}>
        <img src={leftdir} className="h-5" alt="" />
      </button>
      <div
        id="scroll-image"
        className="scrollable-div flex gap-2"
        ref={scrollableDivRef}
      >
        {videoNav?.communityVideos?.map((items: any) => (
          <video
            src={items}
            controls={true}
            height={280}
            width={280}
            // poster={selectedItem.poster}
          />
        ))}
      </div>
      <button onClick={scrollRight}>
        <img src={rightdir} className="h-5" alt="" />
      </button>
    </div>
  );
};

export default VideoNav;
