import frame3 from "../assets/img/Frame 4.png";
import frame2 from "../assets/img/Frame 1.png";
import frame1 from "../assets/img/Frame 3.png";
// import frame2 from "../assets/img/Frame 1.png";
import poster1 from "../assets/img/Poster (3).png";
import poster2 from "../assets/img/Poster (5).png";
import poster3 from "../assets/img/Poster (7).png";
import poster4 from "../assets/img/Poster (4).png";
import editing from "../assets/img/Frame 483.png";
import editing2 from "../assets/img/Frame 483 (3).png";

import pic1 from "../assets/img/Frame 1.png";
import pic2 from "../assets/img/Frame 2.png";
import pic3 from "../assets/img/Frame 3.png";
import pic4 from "../assets/img/Frame 4.png";
import pic5 from "../assets/img/movie.png";

export const Imagess = [
  { Image: poster1 },
  { Image: poster2 },
  { Image: poster3 },
];

export const dropDown = [
  { name: "Premium Membership", linkk: "/Premium-Payment" },
  { name: "Events", linkk: "/Events" },
  // { name: "Job Pool", linkk: "/Explore-Jobs" },
  { name: "Create Jobs", linkk: "/Create-Jobs" },
  { name: "Talent Pool", linkk: "/Talent-Pool" },
  { name: "Settings", linkk: "/Account-settings" },
];


export const seekerDropDown = [
  { name: "Premium Membership", linkk: "/Premium-Payment" },
  { name: "Events", linkk: "/Events" },
  { name: "Job Pool", linkk: "/Explore-Jobs" },
  { name: "Create Jobs", linkk: "/Create-Jobs" },
  { name: "Talent Pool", linkk: "/Talent-Pool" },
  { name: "Settings", linkk: "/Account-settings" },
];


export const employeeSideNav = [
  { name: "Post", linkk: "/" },
  { name: "Settings", linkk: "/Account-settings" },
  { name: "Help & Support", linkk: "/" },
];

export const LoggedOutSideNav = [
  { name: "Refer A Friend", linkk: "/Refer-A-friend" },
  { name: "Help & Support", linkk: "/" },
];

export const userSideNav = [
  { name: "Overview", linkk: "/Dashboard" },
  { name: "Premium Membership", linkk: "/Premium" },
  // { name: "Events", linkk: "/Events" },
  { name: "Experience", linkk: "/Experience-Page" },
  { name: "Skill", linkk: "/Skills-Page" },
  { name: "Award", linkk: "/Award-Page" },
  { name: "Refer A Friend", linkk: "/Refer-A-friend" },
  { name: "Settings", linkk: "/Account-settings" },
  // { name: "Talent Pool", linkk: "/Talent-Pool" },
  { name: "Help & Support", linkk: "/Help-and-Support" },
];

export const settingsSideNav = [{ name: "Account" }, { name: "Security" }];

export const createjobSideNav = [
  { name: "Open Jobs" },
  { name: "Closed Jobs" },
];

export const professions = [
  { name: "Editors" },
  { name: "Motion Graphics" },
  { name: "Animator" },
  { name: "Still Graphics" },
  { name: "Sound Design" },
  { name: "Scores & Audio Mix" },
  { name: "Colourist" },
  { name: "Cinematography" },
  { name: "Visual Effect" },
  { name: "Voice Over" },
];

export const premiumBenefit = [
  { name: "Unlimited Downloads", color: "bg-[#EEEEEE]" },
  { name: "One-On-One Training", color: "bg-[#66758F]" },
  { name: "Ebooks", color: "bg-[#EEEEEE]" },
  { name: "Cancel Anytime", color: "bg-[#66758F]" },
];

export const scrolls = [
  { name: "Editors", link: "Editors" },
  { name: "Motion Graphics", link: "Motion-graphics" },
  { name: "Animator", link: "Animators" },
  { name: "Still Graphics", link: "Still-graphics" },
  { name: "Sound Design", link: "Sound-designn" },
  { name: "Scores & Audio Mix", link: "Scores-Audio" },
  { name: "Colorist", link: "Colourist" },
  { name: "Cinematography", link: "Cinematography" },
  { name: "Visual Effect", link: "Visual_effect" },
  { name: "Voice Over", link: "Voice-over" },
];

export const grid = [
  { name: "Oracle Media" },
  { name: "Oracle Media" },
  { name: "Oracle Media" },
  { name: "Oracle Media" },
  { name: "Oracle Media" },
  { name: "Oracle Media" },
  { name: "Oracle Media" },
  { name: "Oracle Media" },
  { name: "Oracle Media" },
  { name: "Oracle Media" },
  { name: "Oracle Media" },
  { name: "Oracle Media" },
];

export const job_details = [
  { name: "Job Type", details: "Part-Time" },
  { name: "Schedule", details: "Night Shift" },
  { name: "Duration", details: "6 Months" },
  { name: "Location", details: "Lagos" },
  { name: "Job Post", details: "Editors" },
  { name: "Work From", details: "Home" },
];

export const premiumPerks = [
  { name: "Video Tutorials" },
  { name: "Unlimited Downloads" },
  { name: "One on One Tarining" },
  { name: "Ebooks" },
  { name: "Cancel Anytime" },
];

export const commmunity_sidebar = [
  { name: "Community", functionss: "Community" },
  { name: "Join-a-conversation", functionss: "JoinConvo" },
  { name: "Tips & Guides", functionss: "Tips_Guide" },
];

export const Toptrends = [
  { name: "Battle on Buka Street", users: "452" },
  { name: "Black Book", users: "388" },
  { name: "Gangs of Lagos", users: "297" },
];

export const TrendList = [
  { name: "Battle on Buka Street", users: "452" },
  { name: "Black Book", users: "388" },
  { name: "Gangs of Lagos", users: "297" },
  { name: "Sisu", users: "263" },
  { name: "King of boys", users: "255" },
  { name: "Fashion police", users: "239" },
  { name: "Battlefield", users: "230" },
  { name: "Monster Inc", users: "219" },
  { name: "Extraction", users: "183" },
  { name: "Leke", users: "106" },
];

export const suggestedFollow = [
  { name: "Jane", details: "a vast editor" },
  { name: "Kareem", details: "social media influencer" },
  { name: "Jadesola", details: "a register event planner" },
];

export const communityState = [
  { name: "Community" },
  { name: "Trend" },
  { name: "Notification" },
];

export const NotifState = [
  { name: "All" },
  { name: "Posts" },
  { name: "Offers" },
  { name: "Messages" },
];

export const IndividualProfileState = [
  { name: "Latest" },
  { name: "Top" },
  { name: "About" },
];

export const IndividualCommunityState = [{ name: "About" }, { name: "Posts" }];

export const InterestedState = [{ name: "Conversation" }, { name: "Tutorial" }];

export const updatesForYou = [
  { name: "Production View", Image: poster3 },
  { name: "The Critic Hub", Image: poster2 },
  { name: "Tips on Editing", Image: editing },
  { name: "Stoyline Journey", Image: poster4 },
  { name: "Cinematography", Image: editing2 },
  { name: "Production View", Image: poster3 },
  { name: "The Critic Hub", Image: poster2 },
  { name: "Tips on Editing", Image: editing },
  { name: "Stoyline Journey", Image: poster4 },
  { name: "The Critic Hub", Image: poster2 },
  { name: "Tips on Editing", Image: editing },
  { name: "Cinematography", Image: editing2 },
];

export type TimeeLineProps = {
  user?: string;
  content?: string;
  id?: string;
  image?: string;
  status?: string;
  name?: string;
};

export const NotifUpdates = [
  {
    name: "Eleshin Oba",
    status: "Eleshin Oba",
    content: "Grinding at the location and making history",
    img: frame3,
    id: 0,
  },
  {
    name: "BrotherHood",
    status: "Tobi Bakare",
    content:
      "on set and hunting, Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit dolor quas ullam exercitationem hic accusamus quibusdam in aspernatur assumenda consequuntur veniam ab ducimus, tempora itaque, distinctio tenetur enim delectus nemo.",
    // img: frame3,
    id: 1,
  },
  {
    name: "A tribe named judah",
    status: "Funke Akindele",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit dolor quas ullam exercitationem hic accusamus quibusdam in aspernatur assumenda.",
    img: frame2,
    id: 2,
  },
  {
    name: "Black Book",
    status: "RMD",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit dolor quas ullam exercitationem hic accusamus quibusdam in aspernatur assumenda.",
    // img: frame2,
    id: 3,
  },
  {
    name: "BrotherHood",
    status: "Tobi Bakare",
    content:
      "on set and hunting, Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit dolor quas ullam exercitationem hic accusamus quibusdam in aspernatur assumenda consequuntur veniam ab ducimus, tempora itaque, distinctio tenetur enim delectus nemo.",
    // img: frame3,
    id: 4,
  },
  {
    name: "A tribe named judah",
    status: "Funke Akindele",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit dolor quas ullam exercitationem hic accusamus quibusdam in aspernatur assumenda.",
    img: frame2,
    id: 5,
  },
  {
    name: "Black Book",
    status: "RMD",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit dolor quas ullam exercitationem hic accusamus quibusdam in aspernatur assumenda.",
    // img: frame2,
    id: 6,
  },
];

export const communityData = [
  {
    name: "Eleshin Oba",
    status: "Eleshin Oba",
    content: "Grinding at the location and making history",
    img: frame3,
    id: 2,
  },
];

export const commentArray = [
  {
    id: 0,
    name: "Oracle",
    comment:
      "this is a project by the Oracle media hub, bringing post-production professionals into a digital space where tutorials, business and collaborations exist.",
    commentNum: 2,
  },
  {
    id: 1,
    name: "Joke",
    comment:
      "this is a project by the Oracle media hub, bringing post-production professionals into a digital space where tutorials, business and collaborations exist.",
    commentNum: 4,
  },
  {
    id: 2,
    name: "shade",
    comment:
      "this is a project by the Oracle media hub, bringing post-production professionals into a digital space where tutorials, business and collaborations exist.",
    commentNum: 2,
  },
  {
    id: 3,
    name: "tayo",
    comment:
      "this is a project by the Oracle media hub, bringing post-production professionals into a digital space where tutorials, business and collaborations exist.",
    commentNum: 1,
  },
];

export type CommentType = {
  //   name: string;
  //   commentNum: string;
  comment: string;
};

export const commList = [
  { name: "Post City currently top one in forbes list", pics: pic4 },
  { name: "Post City currently top one in forbes list", pics: pic3 },
  { name: "Post City currently top one in forbes list", pics: pic1 },
  { name: "Post City currently top one in forbes list", pics: pic2 },
  { name: "Post City currently top one in forbes list", pics: pic5 },
  { name: "Post City currently top one in forbes list", pics: pic1 },
];

export type JobsType = {
  company: string;
  city: string;
  country: string;
  benefit: string[];
  qualification: string;
  payment: [];
  contract: [];
  workType: [];
  categories: [];
  createdAt: string;
  organization: string;
};

export const contractOption = [
  { value: "Internship", label: "Internship" },
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
  { value: "Contract", label: "Contract" },
  { value: "Temporary", label: "Temporary" },
];

export const paymentOption = [
  { value: "Hourly", label: "Hourly" },
  { value: "Daily", label: "Daily" },
  { value: "Per Contract", label: "Per Contract" },
  { value: "Bimonthly", label: "Bimonthly" },
  { value: "Monthly", label: "Monthly" },
];

export const categoryOption = [
  { value: "Editor", label: "Editor" },
  { value: "Motion Graphics", label: "Motion Graphics" },
  { value: "Animator", label: "Animator" },
  { value: "Still Graphics", label: "Still Graphics" },
  { value: "Sound Design", label: "Sound Design" },
  { value: "Score & Audio Mix", label: "Score & Audio Mix" },
  { value: "Colorist", label: "Colorist" },
  { value: "Cinematography", label: "Cinematography" },
  { value: "Visual Effect", label: "Visual Effect" },
  { value: "Voice Over", label: "Voice Over" },
];

export const jobTypeOption = [
  { value: "On-Site", label: "On-Site" },
  { value: "Remote", label: "Remote" },
  { value: "Hybrid", label: "Hybrid" },
  { value: "Location", label: "Location" },
];

export const locatonOption = [
  { value: "Algeria", label: "Algeria" },
  { value: "Angola", label: "Angola" },
  { value: "Benin", label: "Benin" },
  { value: "Botswana", label: "Botswana" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Chad", label: "Chad" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "DR Congo", label: "DR Congo" },
  { value: "Egypt", label: "Egypt" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Gambia", label: "Gambia" },
  { value: "Ghana", label: "Ghana" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Ivory Coast", label: "Ivory Coast" },
  { value: "Kenya", label: "Kenya" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Mali", label: "Mali" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Namibia", label: "Namibia" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Senegal", label: "Senegal" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "South Africa", label: "South Africa" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Togo", label: "Togo" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Uganda", label: "Uganda" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

export const TimeframeOption = [
  { value: 24, label: "24 hrs" },
  { value: 72, label: "72 hrs" },
  { value: 168, label: "1 week" },
  { value: 720, label: "1 month" },
];
