import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bell from "../../../assets/icons/bi_bell-fill.svg";
import message from "../../../assets/icons/wpf_message.svg";
import dp from "../../../assets/icons/Ellipse 42 (2).svg";
import community from "../../../assets/icons/iconoir_community (1).svg";
import logo from "../../../assets/icons/postcine_logo.png";

type HeaderProps = {
  NotifClick?: () => void;
  DropdownClick?: () => void;
};

const Header: React.FC<HeaderProps> = ({ NotifClick, DropdownClick }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [loggedoutXs, setloggedoutXs] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;

      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setloggedoutXs(!loggedoutXs);
  };

  const id = localStorage.getItem("ids");

  return (
    <>
      <div
        className={`header-content z-[999] px-[10px] xs:px-[20px] sm:px-[40px] lg:px-[60px] bg-white shadow ${
          isSticky
            ? "sticky flex justify-between items-center h-[70px] xs:h-[90px] "
            : " flex justify-between items-center h-[70px] xs:h-[90px]"
        } `}
      >
        <div className="lg:flex gap-[15px] items-center hidden">
          <p
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer hover:text-[#F17300] font-semibold"
          >
            Home
          </p>
          <Link
            to={"/#services"}
            className="cursor-pointer hover:text-[#F17300] font-semibold"
          >
            Service
          </Link>
          <Link
            to={"/#Contact"}
            className="cursor-pointer hover:text-[#F17300] font-semibold"
          >
            Contact
          </Link>
          <button
            onClick={() => {
              navigate("/Community");
            }}
            className="rounded-[5px] shadow text-[#F17300] px-3 h-[34px] shadow-[#F17300]"
          >
            Community
          </button>
        </div>

        <div className="text-[30px] xs:text-[36px] cursor-pointer sm:text-[40px] Montserrat">
          <img
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            className="h-14 w-16"
            alt=""
          />
        </div>

        {id ? (
          <>
            <div className="w-[40px] hidden lg:flex" />
            <div className="flex gap-[11px] xs:gap-[18px]">
              <div className="flex justify-center items-center">
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/Community");
                  }}
                  src={community}
                  alt="/"
                />
              </div>

              <div className="hidden sm:flex justify-center items-center">
                <img src={message} />
              </div>

              <div className="flex justify-center items-center">
                <img
                  onClick={NotifClick}
                  className="cursor-pointer"
                  src={bell}
                />
              </div>

              <div
                onClick={DropdownClick}
                className="flex justify-center items-center"
              >
                <img className="h-[42px] xs:h-[48px] cursor-pointer" src={dp} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="hidden xs:flex gap-[25px]">
              <button
                onClick={() => {
                  navigate("/Signup");
                }}
                className="rounded-[5px] text-[#F17300] shadow w-[100px] md:w-[130px] xl:w-[150px] h-[34px] shadow-[#F17300]"
              >
                Join Now
              </button>
              <button
                onClick={() => {
                  navigate("/Login");
                }}
                className="rounded-[5px] shadow w-[100px] text-[#F17300] md:w-[130px] xl:w-[150px] h-[34px] shadow-[#F17300]"
              >
                Log in
              </button>
            </div>

            <div className="flex xs:hidden gap-3">
              <img
                className="cursor-pointer"
                onClick={() => {
                  navigate("/Community");
                }}
                src={community}
                alt="/"
              />

              <div
                onClick={toggleMenu}
                className="bg-[#f1740079] flex font-black rounded-[10px] h-[27px] w-[40px] justify-center items-center"
              >
                ...
              </div>
            </div>
          </>
        )}
      </div>

      {loggedoutXs && (
        <div className="flex xs:hidden flex-col gap-3 text-sm font-semibold text-[#F17300] items-center py-4">
          <button
            onClick={() => {
              navigate("/Signup");
            }}
            className="h-[42px] w-[95%] rounded-[7px] shadow shadow-[#F17300]"
          >
            JOIN NOW
          </button>
          <button
            onClick={() => {
              navigate("/Login");
            }}
            className="h-[42px] w-[95%] rounded-[7px] shadow shadow-[#F17300]"
          >
            LOG IN
          </button>
        </div>
      )}
    </>
  );
};

export default Header;
