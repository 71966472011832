import React, { useState } from "react";
import Layout from "../../../comonent/Layout";
import { professions } from "../../../utils/data";
import EditorsPage from "../editors/editorsPage";
import Motion_page from "../motn_grapgics";
import AnimatorsPage from "../animators/animatorsPage";
import Sound_designs from "../sound_designs/sound_designsPage";
import Still_graphicsPage from "../still_graphics/still_graphicsPage";
import Scores_Audio_Page from "./scores_audio_page";
import Voice_overPage from "../voice_over/voice_overPage";
import ColouristPage from "../colourist/colouristPage";
import CinematographyPage from "../cinematography/cinematographyPage";
import Visual_effectPage from "../visual_effect/visual_effectPage";

const Scored_AudioMix = () => {
  const [activeTab, setActiveTab] = useState(professions[5].name);

  return (
    <Layout>
      <>
        <div className="flex justify-center font-semibold mt-[35px]">
          <div className="hidden lg:flex text-sm font-bold text-[#4c401d63] ">
            {professions.map((items) => (
              <p
                onClick={() => setActiveTab(items.name)}
                className="border-r px-[10px] xl:px-5 cursor-pointer hover:text-black"
              >
                {items.name}
              </p>
            ))}
          </div>
        </div>

        <div className="lg:hidden grid grid-cols-3 gap-[10px] xs:gap-[15px] sm:gap-5 lg:gap-2 sm:mx-10 md:grid-cols-4 xl:grid-cols-8 justify-center font-semibold">
          {professions.map((items) => (
            <p
              onClick={() => setActiveTab(items.name)}
              className="border-l text-[#4c401d63] hover:text-black text-center text-[12px] lg:text-[14px] cursor-pointer"
            >
              {items.name}
            </p>
          ))}
          <div className="border-l" />
        </div>
      </>

      {activeTab === professions[0].name && <EditorsPage />}
      {activeTab === professions[1].name && <Motion_page />}
      {activeTab === professions[2].name && <AnimatorsPage />}
      {activeTab === professions[3].name && <Still_graphicsPage />}
      {activeTab === professions[4].name && <Sound_designs />}
      {activeTab === professions[5].name && <Scores_Audio_Page />}
      {activeTab === professions[6].name && <ColouristPage />}
      {activeTab === professions[7].name && <CinematographyPage />}
      {activeTab === professions[8].name && <Visual_effectPage />}
      {activeTab === professions[9].name && <Voice_overPage />}
    </Layout>
  );
};

export default Scored_AudioMix;
