import React from 'react'
import LoggedIn_Layout from '../../comonent/loggedIn_Layout';
import Layout from '../../comonent/Layout';
import Job_description_xs from './jobDescription_xs';

const JobDescriXsComponent = () => {
    const User_id = localStorage.getItem("ids");
  return (
 <>
      {User_id ? (
        <LoggedIn_Layout>
          <Job_description_xs />
        </LoggedIn_Layout>
      ) : (
        <Layout>
          <Job_description_xs />
        </Layout>
      )}
    </>
  )
}

export default JobDescriXsComponent