import React from "react";
import UserProfileLayout from "../../../comonent/UserProfile_Layout";
import UserProfilePage from "./userProfile";
import LoggedIn_Layout from "../../../comonent/loggedIn_Layout";
import DashboardSideSideModal from "../../../comonent/DashboardSideModal";
import LogggedOutState from "../../../comonent/Empty_State/loggedOutState";

const UserProfile = () => {
  const user_id = localStorage.getItem("ids");
  return (
    <>
      {user_id ? (
        <LoggedIn_Layout>
          <DashboardSideSideModal>
            <UserProfilePage />
          </DashboardSideSideModal>
        </LoggedIn_Layout>
      ) : (
        <LogggedOutState />
      )}
    </>
  );
};

export default UserProfile;
