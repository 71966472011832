import React, { useEffect, useState } from "react";
import More_projects from "../../../comonent/More-Projects";
import edit from "../../../assets/icons/edit.svg";
import check from "../../../assets/icons/feCheck1.svg";
import ImgUploader from "../../../comonent/ImgUploader";
import LogggedOutState from "../../../comonent/Empty_State/loggedOutState";
import AxiosInstance, { setAccessToken } from "../../../api/axios";
import Skills from "./Skills/skills";
import Experience from "./Experience/experience";
import Review from "./Review/review";

const UserProfilePage = () => {
  const [showUserProfile, setShowUserProfile] = useState(true);
  const [showMoreProjects, setShowMoreProjects] = useState(false);
  const [showAwards, setShowAwards] = useState(false);
  const [comptdProj, setComptdProj] = useState(false);
  const User_id = localStorage.getItem("ids");
  const sessionId = localStorage.getItem("sessionId");
  const firstName = localStorage.getItem("firstName");
  const [parentList, setParentList] = useState([] as any);
  const [parentSkill, setParentSkill] = useState([] as any);
  const [parentReview, setParentReview] = useState([] as any);

  const fetchData = async () => {
    try {
      const res = await AxiosInstance.get(`/user/experiences/${User_id}`);
      if (Array.isArray(res.data)) {
        setParentList(res.data);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSkills = async () => {
    try {
      const res = await AxiosInstance.get(`/user/skills/${User_id}`);
      if (Array.isArray(res.data)) {
        setParentSkill(res.data);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReview = async () => {
    try {
      const res = await AxiosInstance.get(`/user/reviews/${User_id}`);
      if (Array.isArray(res.data)) {
        const parentReview = res.data.map((item) => ({
          review: item.review,
          reviewer_firstName: item.reviewer.firstName,
          reviewer_lastName: item.reviewer.lastName,
          createdAt: item.createdAt,
        }));
        setParentReview(parentReview);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchSkills();
    fetchReview();
  }, []);

  const handleViewMore = () => {
    setShowUserProfile(false);
    setShowMoreProjects(true);
  };

  const handleShowLess = () => {
    setShowMoreProjects(false);
    setShowUserProfile(true);
  };

  return (
    <div className="flex w-full p-3 xs:p-5">
      {User_id ? (
        <>
          {showUserProfile && (
            <div className="flex flex-col gap-[25px] mb-8 w-full">
              <div className="">
                <p>Hi {firstName}</p>
              </div>

              <div className="flex flex-col gap-5">
                <div className="grid grid-cols-3 gap-2 justify-items-center">
                  <div className="shadow shadow-[#f174005f] w-full rounded-[20px] h-[195px] flex justify-center items-center">
                    <ImgUploader />
                  </div>
                  <div className="shadow shadow-[#f174005f] w-full rounded-[20px] h-[195px] flex justify-center items-center">
                    <ImgUploader />
                  </div>
                  <div className="shadow shadow-[#f174005f] w-full rounded-[20px] h-[195px] flex justify-center items-center">
                    <ImgUploader />
                  </div>
                </div>

                <div className="flex justify-end text-[12px] sm:text-[13px] lg:text-[14px]">
                  <p
                    onClick={handleViewMore}
                    className="cursor-pointer text-[#f17400b3] Axiforma"
                  >
                    View more
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 Axiforma">
                <>
                  <Experience parentList={parentList} />
                </>

                <>
                  <Skills parentSkill={parentSkill} />
                </>

                <div className="flex flex-col gap-[25px] py-7 shadow shadow-[#f174005f] p-3 text-[13px] md:text- text-[13px][lg:14px] rounded-[8px]">
                  <div className="flex justify-between">
                    <p className="font-semibold text-[16px]">Awards</p>

                    {showAwards ? (
                      <div className="flex gap-2">
                        <div
                          onClick={() => setShowAwards(false)}
                          className="h-[25px] cursor-pointer relative w-[25px] border border-[#f17400b3] flex justify-center items-center rounded-[25px] bg-gray-100"
                        >
                          <p className="top-[2px] absolute font-bold text-orange-600 text-[15px]">
                            X
                          </p>
                        </div>

                        <div
                          onClick={() => setShowAwards(false)}
                          className="h-[25px] w-[25px] border border-[#f17400b3] flex justify-center items-center rounded-[25px] bg-gray-100"
                        >
                          <img src={check} className="cursor-pointer" alt="/" />
                        </div>
                      </div>
                    ) : (
                      <img
                        onClick={() => setShowAwards(true)}
                        src={edit}
                        className="cursor-pointer"
                        alt="/"
                      />
                    )}
                  </div>
                  <div>
                    {showAwards ? (
                      <textarea className="border-b h-24 p-[10px] nonResizableTextarea w-full rounded-[8px]" />
                    ) : (
                      <ul className="flex flex-col gap-1">
                        <li>- Academy Awards (Oscars) - Best Film Editing</li>
                        <li>- BAFTA Film Awards - Best Editing</li>
                        <li>- Africa Movie Academy Awards (AMAA)</li>
                        <li>- Nollywood Movie Awards</li>
                      </ul>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-[25px]  py-7 shadow shadow-[#f174005f] p-3 text-[13px] md:text-[14px] rounded-[8px]">
                  <div className="flex justify-between">
                    <p className="font-semibold text-[16px]">
                      Completed Projects
                    </p>

                    {comptdProj ? (
                      <div className="flex gap-2">
                        <div
                          onClick={() => setComptdProj(false)}
                          className="h-[25px] cursor-pointer relative w-[25px] border border-[#f17400b3] flex justify-center items-center rounded-[25px] bg-gray-100"
                        >
                          <p className="top-[2px] absolute font-bold text-orange-600 text-[15px]">
                            X
                          </p>
                        </div>

                        <div
                          onClick={() => setComptdProj(false)}
                          className="h-[25px] w-[25px] border border-[#f17400b3] flex justify-center items-center rounded-[25px] bg-gray-100"
                        >
                          <img src={check} className="cursor-pointer" alt="/" />
                        </div>
                      </div>
                    ) : (
                      <img
                        onClick={() => setComptdProj(true)}
                        src={edit}
                        className="cursor-pointer"
                        alt="/"
                      />
                    )}
                  </div>

                  <div>
                    {comptdProj ? (
                      <textarea className="border-b h-24 p-[10px] nonResizableTextarea w-full rounded-[8px]" />
                    ) : (
                      <ul className="flex flex-col gap-1">
                        <li>- Event Highlight Reels</li>
                        <li>- Short Films</li>
                        <li>- Wedding Videos</li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              <>
                <Review ParentReview={parentReview} />
              </>
            </div>
          )}
        </>
      ) : (
        <LogggedOutState />
      )}
      {showMoreProjects && <More_projects onShowLessClick={handleShowLess} />}
    </div>
  );
};

export default UserProfilePage;
