import React, { useEffect, useRef } from "react";
import Layout from "../../comonent/Layout";
import BannerPage from "./Banner";
import SectionOne from "./sectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./sectionThree";
import Advertisement from "../advertisment";
import { handleLogout } from "../../api/axios";

const HomePage = () => {
  // const myRef = useRef<HTMLDivElement>(null);

  // const handleService = () => {
  //   if (myRef.current) {
  //     myRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const User_id = localStorage.getItem("ids");

  // useEffect(() => {
  //   // User_id ? console.log("user id is present") : console.log("there is o user id")
    // const handleLogout = () => {
    //   localStorage.clear();
    //   window.location.href = "/";
    //   // console.log('sjsjsj')
    // };

  // }, []);

  const hanldeBlank = () => {
    // console.log("nothing")
  };

  {
    User_id ? handleLogout() : hanldeBlank();
  }

  // handleLogout()

  return (
    <Layout>
      <BannerPage />
      <SectionOne />
      <Advertisement />
      <SectionTwo />
      <SectionThree />
    </Layout>
  );
};

export default HomePage;
