import React from 'react'
import illustration from "../../assets/img/Illustration.png";
import arrow from "../../assets/icons/auth_arrow.svg";
import { useNavigate } from "react-router-dom";
import google from "../../assets/icons/Google svg.svg";


const ResetPassword = () => {
    const navigate = useNavigate();

  return (
    <div className="flex">
      <div className="bg-[#f1730066] px-7 h-[100vh] lg:px-0 w-[500px] md:w-[700px] lg:w-[900px] hidden md:flex justify-center items-center">
        <div className="flex flex-col items-center justify-center gap-[50px]">
          <img src={illustration} />
          <p className="text-[26px] text-white lg:text-[31px] font-semibold lg:w-[95%] text-center">
            Online Community For Post-production Professionals
          </p>
        </div>
      </div>
      <div className="bg-white w-full">
        <div className="pt-[15px] px-[15px] xs:px-[30px]">
          <img
            onClick={() => navigate("/Dashboard")}
            className="cursor-pointer"
            src={arrow}
          />
        </div>

        <div className="w-full flex justify-center my-10">
          <div className="w-[85%] xs:w-[80%] sm:w-[65%] lg:w-[60%] xl:w-[50%] flex flex-col gap-[32px]">
            <div className="flex flex-col gap-[48px]">
              <div>
                <p className="text-[30px]">
                  Reset Password
                </p>
                <p className='Lato-Italics'>We have sent a four digit code to your email</p>
              </div>

              <div className="flex flex-col gap-[15px] sm:gap-[30px] font-semibold">
                <div className="flex flex-col gap-[8px]">
                  <p className='font-normal'>Enter four digit code</p>
                  <input
                    className="w-full text-black h-[51px] p-[16px] font-normal border-b border-black bg-[#F6F6F6]"
                  />
                </div>

                <div className="flex flex-col gap-[8px]">
                  <p  className='font-normal'>New Password</p>
                  <input
                    className="w-full text-black h-[51px] p-[16px] border-b border-black bg-[#F6F6F6] font-normal"
                  />
                </div>

                <div className="flex flex-col gap-[8px]">
                  <p className='font-normal'>Confirm Password</p>
                  <input
                    className="w-full text-black h-[51px] p-[16px] border-b border-black bg-[#F6F6F6] font-normal"
                  />
                </div>
              </div>

              <div className="flex justify-center gap-[24px] font-semibold">
              <button className="flex shadow shadow-[#f17400b4] hover:bg-[#f17400b4] hover:text-white w-[180px] h-[48px] justify-center items-center rounded-[5px]">
                Reset Password
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword