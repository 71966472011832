import React, { useState } from "react";
import illustration from "../../assets/img/Illustration.png";
import arrow from "../../assets/icons/auth_arrow.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Congratulation = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");
  const verificationToken = localStorage.getItem("verificationToken");

  const handleSubmit = () => {
    setIsLoading(true);
    const newUserData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      verificationToken: verificationToken,
      password: password,
      role: "",
    };


    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/register`, newUserData)

      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("ids", response.data.data.user._id);
          localStorage.setItem("password", "");
          // toast.success("new user created", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          navigate("/ChooseType");
        }
      })
      .catch(({ error, response }) => {
        // console.log(response);
        if (!response) {
          toast("Please check internet connection", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.fields.email, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
 
  };

  return (
    <div className="flex h-[100vh]">
      <div className="bg-[#f1730066] px-7 lg:px-0 w-[500px] md:w-[700px] lg:w-[900px] hidden md:flex justify-center items-center h-[100vh]">
        <div className="flex flex-col items-center justify-center gap-[50px]">
          <img src={illustration} />
          <p className="text-[26px] text-white lg:text-[31px] font-semibold lg:w-[95%] text-center">
            Online Community For Post-production Professionals
          </p>
        </div>
      </div>
      <div className="bg-white w-full">
        <div className="w-full flex items-center h-[100vh] justify-center mb-10">
          <div className="font-semibold items-center justify-center w-[80%] sm:w-[65%] lg:w-[60%] pb-10 xl:w-[50%] flex flex-col gap-[32px]">
            <div className="w-full flex flex-col gap-14 items-center h-[100vh] justify-center mb-10">
              <p className="text-[36px]">congratulations</p>

              <div className="w-full">
                <button
                  onClick={handleSubmit}
                  className={`flex shadow w-full shadow-[#F17300] hover:text-white hover:bg-[#f1740091] h-[38px] font-semibold justify-center items-center rounded-[5px]`}
                >
                  {isLoading ? "Loading..." : "Let's go"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      /
    </div>
  );
};

export default Congratulation;
